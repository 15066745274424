import React, {useEffect, useState} from 'react';

function PerCount(props: { animation: boolean; count: number }) {
    const [count, setCount] = useState<number>(0.00)
    useEffect(()=> {
        if( props.animation ){
            const endPoint = props.count
            setTimeout(()=> {
                if( count < endPoint ){
                    setCount(count + 1)
                }else{
                    setCount(endPoint)
                }
            }, 5)
        }
    })
    return (
        <span className={"per-count"}>{count}%</span>
    );
}

PerCount.defaultProps = {
    animation: true
}

export default PerCount;