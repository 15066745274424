import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faComment } from '@fortawesome/free-solid-svg-icons';

function GameBoardUserPick(props: {}) {
    return (
        <div className={"result-board left"}>
            <div className={"rotate-board"}>
                <header className={"title"}>유저픽</header>
                <main className={"contents"}>
                    <div className={"scroll"}>
                        {
                            [...Array(6)].map((_, index)=>
                                <ul key={index}>
                                    <li className={"items"}>
                                        <span className="win-count"></span>
                                        <span className={"icon"}>
                                        <FontAwesomeIcon icon={faUser}/>
                                    </span>
                                        <span className="name"></span>

                                        <span className={"rect odd"}></span>
                                        <span className={"join-chat"}>
                                        <FontAwesomeIcon icon={faComment}/>
                                    </span>
                                    </li>
                                    <li className={"items"}>
                                        <span className="win-count"></span>
                                        <span className={"icon"}>
                                        <FontAwesomeIcon icon={faUser}/>
                                    </span>
                                        <span className="name"></span>

                                        <span className={"rect odd"}></span>
                                        <span className={"join-chat"}>
                                        <FontAwesomeIcon icon={faComment}/>
                                    </span>
                                    </li>
                                    <li className={"items"}>
                                        <span className="win-count"></span>
                                        <span className={"icon"}>
                                        <FontAwesomeIcon icon={faUser}/>
                                    </span>
                                        <span className="name"></span>

                                        <span className={"rect odd"}></span>
                                        <span className={"join-chat"}>
                                        <FontAwesomeIcon icon={faComment}/>
                                    </span>
                                    </li>
                                </ul>
                            )
                        }
                    </div>
                </main>
            </div>
        </div>
    );
}

export default GameBoardUserPick;
