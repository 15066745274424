import React, {useEffect, useState} from 'react';
import SqsEffect, {ef, ef_i, ef_timeout} from '../Effect';
import stop from "../asset/sound/Stop.mp3"
import AudioPlayer from "./audio_player";

export default function Spin(props: { index: number; state: string; number: number; powerball: boolean; }) {
    const [audioPlay, setAudioPlay] = useState(false)
    const [spinState, setSpinState] = useState('ready')
    let addClass = "spin-key-" + props.index;
    if(props.powerball){
        addClass = " powerball " + addClass
    }
    const effect_name = "spin-"+props.index
    const effect = new SqsEffect(effect_name)
    const spin_effect_name = effect_name+"-effect"
    const spin_effect = new SqsEffect(spin_effect_name)

    useEffect(()=> {
        if( props.state === 'end' ){
            let count = props.index
            setTimeout(()=>{
                setSpinState(props.state)
            }, 1500 * count)
        }else{
            setTimeout(()=>{
                setSpinState(props.state)
            }, 0)
        }
    }, [props.state])
    useEffect(()=> {
        // console.log(props.number)
        let st = {
            imageSize : [800, 744],
            frame     : 60,
            vector    : [10, 6],
            speed     : 900,
            element   : ".spin-items.spin-key-"+props.index+" > .spin-figure",
            loop      : true,
        }
        if(props.powerball === true){
            st.imageSize = [940, 870]
        }
        if(spinState === 'spin'){
            effect.set(st)
            let st_2 = {
                imageSize : [800, 744],
                frame     : 60,
                vector    : [10, 6],
                speed     : 900,
                element   : ".spin-items.spin-key-"+props.index+" > .spin-effect",
                loop      : true,
                loop_end  : true,
            }
            if(props.powerball === true){
                st_2.imageSize = [940, 870]
            }
            spin_effect.set(st_2)
        }else if(spinState === 'ready'){
            effect.close(effect_name)
            spin_effect.close(spin_effect_name)
            spin_effect.reset(".spin-items.spin-key-"+props.index+" > .spin-effect")
            // return () => console.log(state);
        }else if(spinState === 'end'){
            effect.close(effect_name)
            spin_effect.close(spin_effect_name)
            spin_effect.reset(".spin-items.spin-key-"+props.index+" > .spin-effect")
            spinEnd()
            // setTimeout(function(){
            // }, 1000 * props.index)
        }
        // console.log(spinState)
    }, [spinState])
    function spinEnd(){
        let st = {
            imageSize : [400, 496],
            frame     : 20,
            vector    : [5, 4],
            speed     : 900,
            element   : ".spin-key-"+props.index+" > .spin-figure",
            loop      : false,
        }
        if(props.powerball === true){
            st.imageSize = [470, 580]
        }
        effect.set(st, function(){
            setAudioPlay(true)
            setTimeout(()=> {
                setAudioPlay(false)
            })
        })
    }
    return (
        <div className={"spin-items " + addClass}>
            <div className={"spin-effect " + addClass}></div>
            <AudioPlayer src={stop} play={audioPlay}/>
            {
                props.number >= 0 ? <figure className={`spin-figure ${spinState} number-${props.number}`} ></figure>
                    : <figure className={`spin-figure ${spinState}`} ></figure>
            }

        </div>
    );
}
Spin.defaultProps = {
    powerball: false,
    state: "ready",
    number: 0
}