import React, {useEffect, useState} from 'react';

function GameBoardTimerCircleGraph(props: { per: number}) {
    const RADIUS = 46.5;
    const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
    const CIRCUMFERENCE_FIXED = Number(CIRCUMFERENCE.toFixed(0))
    const progress = props.per / 100;
    const offset = Number((CIRCUMFERENCE * (1 - progress)).toFixed(0));

    const [dashOffset, setDashOffset] = useState(CIRCUMFERENCE_FIXED)
    useEffect(()=> {
        // console.log(CIRCUMFERENCE);
        // console.log(dashOffset);
        setDashOffset(offset)
    }, [props.per])
    return (
        <div className="circle_progress_wrap">
            <svg className={"circle_progress "} width="192" height="192" viewBox="0 0 100 100">
                <circle className="bar" cx="50" cy="50" r="46.4"
                        strokeWidth="4"
                        strokeDasharray={CIRCUMFERENCE}
                        strokeDashoffset={dashOffset}
                />
            </svg>
        </div>
    );
}
GameBoardTimerCircleGraph.defaultProps = {
    per: 0
}

export default GameBoardTimerCircleGraph;