import React, {useEffect, useState} from 'react';

function PickBar(props: {className: string; count: number}) {
    const [perCount, setAddCount] = useState(0)
    useEffect(()=> {
        const endPoint = props.count
        setTimeout(()=> {
            if(perCount < endPoint){
                setAddCount(perCount + 1)
            }
        }, 5)
    })
    return (
        <div className={props.className} style={{ width: `${ perCount + "%" }` }}></div>
    );
}

export default PickBar;