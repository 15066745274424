import React, {createContext, useState} from 'react';

const MuteContext = createContext({
    mute: false,
    toggleMute: ()=> {}
})

function MuteProvider( props: { children: React.ReactElement }) {
    const [mute, setMute] = useState<boolean>(false);
    const toggleMute = (): void => {
        setMute(!mute)
    }
    return (
        <MuteContext.Provider value={{ mute, toggleMute }} >
            {props.children}
        </MuteContext.Provider>
    );
}

export {MuteContext, MuteProvider};
