import React, {useEffect, useRef, useState} from 'react';
import Spin from "./spin";
import {GameResult, LABELS} from "../interface";
import AudioPlayer from "./audio_player";
import startBgm from "../asset/sound/Start.mp3"
import GameBoardTimerCircle from "./game_board_timer_circle";

const ball_number_obj ={
    normal: [0, 0, 0, 0, 0],
    powerball: 0
}
function GameBoard({gameResults}: { gameResults: GameResult[] }) {
    const [state, setState] = useState("stay")
    const [bgmState, setBgmState] = useState(false)
    const [resultPopup, setResultPopup] = useState(false)
    const [ballNumber, setBallNumber] = useState(ball_number_obj)

    // console.log(state)
    useEffect(()=> {
        // console.log(state)
        if(state == 'ready'){
            setTimeout(()=> {
                setState('spin')
            }, 0)
        }else if(state == 'spin'){
            setBgmState(true)
            setTimeout(()=> {
                setState('end')
            }, 1000)
        }else if(state == 'end'){
            setBgmState(false)
            setTimeout(()=> {
                setResultPopup(true)
            }, 10000)
            setTimeout(()=> {
                setState('stay')
                setResultPopup(false)
            }, 13500)
        }
    }, [state])
    useEffect(()=> {
        const round_ball_data = gameResults.first()
        const normal_ball_sort = round_ball_data.balls
        // @ts-ignore
        ball_number_obj.normal = normal_ball_sort;
        ball_number_obj.powerball = round_ball_data.happy_ball;
        setBallNumber(ball_number_obj)

        setState('ready')
    }, [gameResults])
    useEffect(()=> {
        setState('stay')
    }, [])
    return (
        <>
            <AudioPlayer src={startBgm} play={bgmState} />
            <div className={"game-board"} >
                {/*<div className={"board-grid"}></div>*/}
                <div className={"board-logo"}></div>
                <div className={"board-logo effect"}></div>
                <div className={"board-effect " + state}></div>
                <div className={"spin-bg"}></div>
                <div className={"spin-bg effect " + state}>{/* 게임 끝날 때 add class: effect */}
                </div>
                {
                    resultPopup ? <GameResultPopup gameResults={gameResults}/>
                                : ""
                }
                <div className={"spin-container"}>
                    <Spin index={1} state={state} number={ballNumber.normal[0]}/>
                    <Spin index={2} state={state} number={ballNumber.normal[1]}/>
                    <Spin index={3} state={state} number={ballNumber.normal[2]}/>
                    <Spin index={4} state={state} number={ballNumber.normal[3]}/>
                    <Spin index={5} state={state} number={ballNumber.normal[4]}/>
                    <Spin index={6} state={state} number={ballNumber.powerball} powerball={true}/>
                </div>
            </div>
            {/* 게임 시간 */}
            {
                // state == 'stay' ? <GameBoardTimer round={gameResults.first().round_daily} endpoint={gameResults.first().game_time} />
                state == 'stay' ? <GameBoardTimerCircle round={gameResults.first().round_daily} endpoint={gameResults.first().game_time} />
                                 : <></>
            }
        </>
    );
}

export default GameBoard;

function GameResultPopup({gameResults}: { gameResults: GameResult[] }){
    const game_result = gameResults.first()
    return (
        <div className={"result-card"}>
            <div className="inner">
                <div className="round">{game_result.round_daily}회차 결과</div>
                <div className={"numbers"}>
                    <span className={"num-" + game_result.balls[0]}>{ game_result.balls[0] }</span>
                    <span className={"num-" + game_result.balls[1]}>{ game_result.balls[1] }</span>
                    <span className={"num-" + game_result.balls[2]}>{ game_result.balls[2] }</span>
                    <span className={"num-" + game_result.balls[3]}>{ game_result.balls[3] }</span>
                    <span className={"num-" + game_result.balls[4]}>{ game_result.balls[4] }</span>
                    <span className={"powerball"}>{ game_result.happy_ball }</span>
                </div>
                <div className={"ranges"}>
                    {/* happyball 홀짝 */}
                    <span className={"rect " + game_result.happy_ball_odd_even}>
                                            {"P" + LABELS[game_result.happy_ball_odd_even]}
                                        </span>
                    {/* happyball 언오 */}
                    <span className={"rect " + game_result.happy_ball_under_over}>
                                            {"P" + LABELS[game_result.happy_ball_under_over]}
                                        </span>
                    {/* 일반 홀짝 */}
                    <span className={"rect " + game_result.balls_odd_even}>
                                            {LABELS[game_result.balls_odd_even]}
                                        </span>
                    {/* 일반 언오 */}
                    <span className={"rect " + game_result.balls_under_over}>
                                            {LABELS[game_result.balls_under_over]}
                                        </span>
                    {/* 소, 중, 대 */}
                    <span className={"rect " + game_result.balls_size}>
                                            {LABELS[game_result.balls_size]}
                                        </span>
                </div>
            </div>
        </div>
    )
}