import {createContext} from "react";

export type GameType =
    "happy_ball_odd_even"
    | "happy_ball_under_over"
    | "happy_ball_section"
    | "balls_odd_even"
    | "balls_under_over"
    | "balls_size"
    | "balls_section"

export interface GameResultData {
    round: number
    game_begin_dt: string
    happy_ball: number
    balls: number[]
}

export interface GameResult extends GameResultData {
    round_daily: number
    game_date: string
    game_time: string
    balls_sum: number

    happy_ball_odd_even: 'odd' | 'even'
    happy_ball_under_over: 'under' | 'over'
    happy_ball_section: 'A' | 'B' | 'C' | 'D'
    balls_odd_even: 'odd' | 'even'
    balls_under_over: 'under' | 'over'
    balls_size: 'small' | 'medium' | 'large'
    balls_section: 'A' | 'B' | 'C' | 'D' | 'E' | 'F'
}

export interface GameResultStats {
    numOfTurn: number
    numOfSingle: number
    numOfMultiple: number
    maxLength: number
    countMap: any
    maxLengthMap: any
    maxSingleInRow: number
}

export const GAME_BOARD_TIMER = 306

/** hidden tab
 *  "happy_ball_section", "balls_section"
 *
 * */
export const GAME_TYPES: GameType[] = ["happy_ball_odd_even", "happy_ball_under_over", "balls_odd_even", "balls_under_over", "balls_size"]

export const GAME_TYPE_OPTIONS = {
    happy_ball_odd_even: ['odd', 'even'],
    happy_ball_under_over: ['under', 'over'],
    happy_ball_section: ['A', 'B', 'C', 'D'],
    balls_odd_even: ['odd', 'even'],
    balls_under_over: ['under', 'over'],
    balls_size: ['small', 'medium', 'large'],
    balls_section: ['A', 'B', 'C', 'D', 'E', 'F'],
}

export const LABELS = {
    happy_ball_odd_even: "파워볼-홀짝",
    happy_ball_under_over: "파워볼-오버언더",
    happy_ball_section: "해피볼-구간",
    balls_odd_even: "일반볼-홀짝",
    balls_under_over: "일반볼-오버언더",
    balls_size: "일반볼-대중소",
    balls_section: "일반볼-구간",
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    small: "소",
    medium: '중',
    large: '대',
    odd: "홀",
    even: "짝",
    under: "언",
    over: "오",
}

export const STANDARS = {
    HAPPY_BALL_UNDER_OVER: 4.5,
    HAPPY_BALL_A_B: 2.5,
    HAPPY_BALL_B_C: 4.5,
    HAPPY_BALL_C_D: 6.5,
    BALL_UNDER_OVER: 72.5,
    BALL_SMALL_MEDIUM: 64.5,
    BALL_MEDIUM_LARGE: 80.5,
    BALL_A_B: 35.5,
    BALL_B_C: 49.5,
    BALL_C_D: 57.5,
    BALL_D_E: 65.5,
    BALL_E_F: 78.5
}

export const DEFAULT_STATS_TYPE = GAME_TYPES[0]

declare global {
    interface Array<T> {
        last(): T
        first(): T
        numbericSort(): T
    }
}

Array.prototype.last = function () {
    return this[this.length - 1];
};
Array.prototype.first = function () {
    return this[0];
};
Array.prototype.numbericSort = function () {
    return this.sort(function (a, b) { return a - b; });
};
