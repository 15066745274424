export const ef = new Object();
export const ef_i = new Object();
export const ef_timeout = new Object();
export default class SqsEffect {
    constructor(name) {
        this.n = name
    }

    set(st, fc){
        ef[this.n] = [0,0]
        this.close(this.n)

        const a  = parseInt(st.imageSize[0]);
        const b  = parseInt(st.imageSize[1]);
        const f  = parseInt(st.frame);
        const l  = st.vector;
        const s  = parseInt(st.speed);
        const ele = document.querySelector(st.element);
        const lp = (st.loop === undefined) ? false : st.loop;
        const tim = (st.timeout === undefined) ? false : st.timeout;

        const t = (s / f);

        let remain_time = 0
        ef_timeout[this.n] = setInterval(()=>{
            remain_time++;
        }, 100)


        let i_cnt = 1;
        ef_i[this.n] = setInterval(()=>{
            this.stP(a, b, l, ele);
            this.stNIdx(l);

            if( tim && tim === remain_time * 100 ){
                this.close(this.n);
            }else if( lp === false && i_cnt === f ){
                this.close(this.n);
                if(fc){
                    fc()
                }
            }
            i_cnt++;
        }, t)
    }

    stP(a, b, l, ele){
        const ps_x = -1 * (a / l[0]);
        const ps_Y = -1 * (b / l[1]);

        const x = ef[this.n][0];
        const y = ef[this.n][1];

        const s_ps = (ps_x * x) + "px " + (ps_Y * y) + "px";

        if( ele){
            ele.style.backgroundPosition = s_ps
        }else{
            this.close(this.n);
        }
    }

    stNIdx(l){
        if(ef[this.n][0] < (l[0] - 1)){
            ef[this.n][0] = ef[this.n][0] + 1;
        }else{
            ef[this.n][0] = 0;
            if(ef[this.n][1] < (l[1] - 1)){
                ef[this.n][1] = ef[this.n][1] + 1;
            }else{
                ef[this.n][1] = 0;
            }
        }
    }

    close(n) {
        if(n){
            clearInterval(ef_timeout[n]);
            clearInterval(ef_i[n]);
        }else{
            for(const i in ef_timeout){
                clearInterval(ef_timeout[i])
            }
            for(const i in ef_i){
                clearInterval(ef_i[i])
            }
        }
    }

    reset(ele){
        document.querySelector(ele).style.backgroundPosition = "0 0";
    }
}