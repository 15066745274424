import React, {useEffect, useState} from 'react';
import {GameResult, LABELS} from "../interface";
import MobileResultBoard from "./mobile_result_board";

function GameBoardResult(gameResults: { gameResults: GameResult[]; }) {
    const [state, setState] = useState<string>("")
    const list = gameResults.gameResults
    useEffect(()=> {
        setState('--hidden')
        setTimeout(()=> {
            setState('')
        }, 14500)
    }, [gameResults])
    useEffect(()=> {
        setState('')
    }, [])
    return (
        <>
            <div className={"result-board right"}>
            <div className="rotate-board">
                <header className={"title"}>추첨결과</header>
                <main className={"contents"}>
                    <div className={"scroll"}>
                        <ul>
                            {
                                list.map((obj, index)=>
                                    <li key={index} className={"items " + ( index == 0 ? state : "" )}>
                                        <div className="round">{obj.round_daily}회차 {obj.round}</div>
                                        <div className={"numbers"}>
                                            <span className={"num-" + obj.balls[0]}>{ obj.balls[0] }</span>
                                            <span className={"num-" + obj.balls[1]}>{ obj.balls[1] }</span>
                                            <span className={"num-" + obj.balls[2]}>{ obj.balls[2] }</span>
                                            <span className={"num-" + obj.balls[3]}>{ obj.balls[3] }</span>
                                            <span className={"num-" + obj.balls[4]}>{ obj.balls[4] }</span>
                                            <span className={"powerball"}>{ obj.happy_ball }</span>
                                        </div>
                                        <div className={"ranges"}>
                                            {/* happyball 홀짝 */}
                                            <span className={"rect " + obj.happy_ball_odd_even}>
                                            {"P" + LABELS[obj.happy_ball_odd_even]}
                                        </span>
                                            {/* happyball 언오 */}
                                            <span className={"rect " + obj.happy_ball_under_over}>
                                            {"P" + LABELS[obj.happy_ball_under_over]}
                                        </span>
                                            {/* 일반 홀짝 */}
                                            <span className={"rect " + obj.balls_odd_even}>
                                            {LABELS[obj.balls_odd_even]}
                                        </span>
                                            {/* 일반 언오 */}
                                            <span className={"rect " + obj.balls_under_over}>
                                            {LABELS[obj.balls_under_over]}
                                        </span>
                                            {/* 소, 중, 대 */}
                                            <span className={"rect " + obj.balls_size}>
                                            {LABELS[obj.balls_size]}
                                        </span>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </main>
            </div>
        </div>

            <MobileResultBoard list={list} state={state} />
        </>
    );
}

export default GameBoardResult;