import React, {useEffect, useState} from 'react';
import GameBoard from "./game_board";
import GameBoardGlobalTimer from "./game_board_global_timer";
import {GAME_BOARD_TIMER, GameResult} from "../interface";
import {getLeftTime, makeGameResults} from "../index";
import GameBoardUserPick from "./game_board_user_pick";
import GameBoardResult from "./game_board_result";
import GameBoardStatistic from "./game_board_statistic";
import IconMenus from "./icon_menus";
import {MuteProvider} from './muteContext'


function HappyBallGameBoardIframe(props:{}) {
    const [gameResults, setGameResults] = useState<GameResult[]>();
    const fetch_url = "/api/happyball/results/"
    // const fetch_url = "http://127.0.0.1:8000/api/happyball/results/"

    useEffect(() => {
        fetch(fetch_url)
            .then(async response => {
                if (response.ok) {
                    const data = await response.json();
                    setGameResults(makeGameResults(data));
                } else {
                    console.log('Game result Fetch Error');
                }
            })
    }, [])

    function fetchResults(): any {
        if (!gameResults) {
            return
        }
        // console.log(gameResults.last())
        // 현재 state에 포함된 가장 마지막 라운드 이후 데이터를 요청
        const fromRound = gameResults[0].round + 1
        fetch(`${fetch_url}?from=${fromRound}`)
            .then(async response => {
                if (response.ok) {
                    const data = await response.json();
                    let fetchedResults = makeGameResults(data);
                    if (fetchedResults.length > 0) {
                        setGameResults(fetchedResults.concat(gameResults.slice(0, -fetchedResults.length)));
                    }
                } else {
                    alert('게임 결과 fetch 실패');
                }
            })
    }

    // 결과 값이 추가될 때마다 다음 결과값 요청을 위한 세팅
    // 다음 라운드 결과가 나올 때까지 기다렸다가 (getLeftTime())
    // 매 5초마다 요청(성공 시 clearInterval)
    useEffect(() => {
        if (!gameResults) {
            return
        }
        // console.log(getLeftTime(gameResults[0].game_begin_dt))
        let intervalId: NodeJS.Timer
        let timeoutId = setTimeout(() => {
            fetchResults();
            intervalId = setInterval(fetchResults, 1000)
        }, getLeftTime(gameResults[0]?.game_begin_dt))

        return () => {
            clearTimeout(timeoutId)
            clearInterval(intervalId)
        }
    }, [gameResults])

    if (gameResults) {
        // console.log(gameResults.first())
        return (
            <MuteProvider>
                <div id={"happyball-reel-game"}>
                    {/* 게임 배경 */}
                    <GameBoard gameResults={gameResults} />
                    {/* 아이콘 */}
                    <IconMenus />
                    {/* 현재 시간 */}
                    <GameBoardGlobalTimer />

                    {/* 왼쪽 */}
                    <GameBoardUserPick />
                    {/* 오른쪽 */}
                    <GameBoardResult gameResults={gameResults} />

                    {/* 아래 통계 */}
                    <GameBoardStatistic gameResults={gameResults}/>
                </div>
            </MuteProvider>
        )
    } else {
        return <div>로딩중</div>
    }
}
// @ts-ignore
export default HappyBallGameBoardIframe; // @ts-ignore