import React from 'react';

function GraphSML(props: { s: number; m: number; l: number;  }) {
    const graphStyle = {
        backgroundImage: `${"conic-gradient("
            + "#2185D0 0%,"
            + "#2185D0 "+ props.s +"%,"
            + "#DB2828 "+ props.s +"%,"
            + "#DB2828 "+ (props.s + props.m) +"%,"
            + "#22DC00 "+ (props.s + props.m) +"%,"
            + "#22DC00 100%"
        + ")"}`
    };
    return (
        <div className={"graph"} style={graphStyle}></div>
    );
}

export default GraphSML;