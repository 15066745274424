import React, {useContext, useEffect, useRef, useState} from 'react';
import ranking from '../asset/icon/Icon metro-trophy.svg'
import home from '../asset/icon/Icon ionic-ios-home.svg'
import help from '../asset/icon/Icon ionic-md-help-circle.svg'
import share from '../asset/icon/Icon metro-share.svg'
import bell from '../asset/icon/Icon awesome-bell.svg'
import bell_slash from '../asset/icon/Icon awesome-bell-slash.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {MuteContext} from "./muteContext";


function IconMenus(props: {}) {
    const { toggleMute } = useContext(MuteContext)
    const [bellState, setBellState] = useState(bell)
    const [copyState, setCopyState] = useState<boolean>()
    const [shareShow, setShareShow] = useState<boolean>(false)

    const iframe_location = "https://www.happyball.co.kr"
    // const iframe_location = window.location.origin
    function goHome() {
        window.parent.location.href = iframe_location
    }
    const iframe_url = '<iframe src="'+iframe_location+'/game/" width="840" height="600" frameborder="0" allow="clipboard-read; clipboard-write"></iframe>'
    const iframe_url_mo = '<iframe src="'+iframe_location+'/game/" width="840" height="750" frameborder="0" allow="clipboard-read; clipboard-write" style="max-width: 100%;"></iframe>'
    function clipboard(){
        try {
            navigator.clipboard.writeText(iframe_url)
            setCopyState(true)
        }catch (e) {
            console.log(e)
            setCopyState(false)
        }
    }
    function clipboard_mobile(){
        try {
            navigator.clipboard.writeText(iframe_url_mo)
            setCopyState(true)
        }catch (e) {
            console.log(e)
            setCopyState(false)
        }
    }
    function toggleBell(){
        toggleMute()
        if( bellState == bell ){
            setBellState(bell_slash)
        }else {
            setBellState(bell)
        }
    }
    useEffect(()=> {
        if(copyState){
            alert('클립보드에 복사되었습니다.')
            setCopyState(false)
        }
    }, [copyState])
    return (
        <>
            <div className={"icon-menus"}>
                <div className={"left"}>
                    <span><img src={ranking} alt="ranking"/></span>
                    <span onClick={goHome}><img src={home} alt="home"/></span>
                </div>
                <div className={"right"}>
                    <span><img src={help} alt="help"/></span>
                    <span onClick={()=>setShareShow(!shareShow)}><img src={share} alt="share"/></span>
                    <span onClick={toggleBell} className={"bell "}><img src={bellState} alt="bell"/></span>
                </div>

            </div>

            {
                shareShow ? <div className={"share-popup"}>
                        <button onClick={()=>setShareShow(false)}>
                            <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                        </button>
                        <div className={"inner"}>
                            <p className="title">중계화면 퍼가기</p>
                            <p className="title --mt-10">PC 버전</p>
                            <div className={"contents"}>
                                <textarea defaultValue={iframe_url}></textarea>
                                <div>
                                    <button onClick={clipboard}>코드복사</button>
                                </div>
                            </div>
                            <p className="title --mt-20">모바일 버전</p>
                            <div className={"contents"}>
                                <textarea defaultValue={iframe_url_mo}></textarea>
                                <div>
                                    <button onClick={clipboard_mobile}>코드복사</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
            }

        </>
    );
}

export default IconMenus;