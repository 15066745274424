import React from 'react';
import PerCount from "../../minigame/per_count";
import PickBar from "../../minigame/pick_bar";
import {GameResult} from "../interface";
import {getGraphDataObject} from "../index";


function GameBoardStatistic(gameResults: { gameResults: GameResult[]; }) {
    const data = getGraphDataObject(gameResults)
    const powerBall  = data.powerBall
    const normalBall = data.normalBall
    const sml        = data.sml

    const PICK_data = [
        { "pick_name": "파워볼 홀짝",   "o": powerBall.percent.odd,    "l": powerBall.percent.even , "o_name": "홀",   "l_name": "짝" },
        { "pick_name": "파워볼 언오",   "o": powerBall.percent.under,  "l": powerBall.percent.over , "o_name": "언더", "l_name": "오버" },
        { "pick_name": "일반볼 홀짝",   "o": normalBall.percent.odd,   "l": normalBall.percent.even, "o_name": "홀",   "l_name": "짝" },
        { "pick_name": "일반볼 언오",   "o": normalBall.percent.under, "l": normalBall.percent.over, "o_name": "언더", "l_name": "오버" },
    ]
    const PICK_sum = [
        { "name": "대", "per": sml.percent.s },
        { "name": "중", "per": sml.percent.m },
        { "name": "소", "per": sml.percent.l }
    ]
    const PICK_win = [
        (PICK_data[0].o > PICK_data[0].l ? "odd" : "even"),
        (PICK_data[1].o > PICK_data[1].l ? "under" : "over"),
        (PICK_data[2].o > PICK_data[2].l ? "odd" : "even"),
        (PICK_data[3].o > PICK_data[3].l ? "under" : "over")
    ]
    return (
        <div className={"board-statistic"}>
            <div className={"rotate-board"}>
                <div className="inner-bg">
                    { PICK_data.map( (items, key)=>(
                        <div className={"pick-bar"} key={key}>
                            <div className={"pick-item " + "--bg-gb-" + PICK_win[key]}>{ items.pick_name }</div>
                            <div className={"pick-count " + PICK_win[key]}>
                                <div>
                                    <span>{items.o_name}</span>
                                    <PerCount count={items.o}/>
                                </div>
                                <div>
                                    <span>{items.l_name}</span>
                                    <PerCount count={items.l}/>
                                </div>
                            </div>
                            <div className={"absolute-bar"}>
                                <div>
                                    <PickBar className={"odd-under"} count={items.o}/>
                                </div>
                                <div>
                                    <PickBar className={"even-over"} count={items.l}/>
                                </div>
                            </div>
                        </div>
                    ) ) }
                    <div className={"pick-sum"}>
                        { PICK_sum.map( (items, key)=>(
                            <div key={key}>
                                <div>{items.name}</div>
                                <PerCount count={items.per}/>
                            </div>
                        ) ) }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GameBoardStatistic;