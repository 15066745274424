import React, {useEffect, useState} from 'react';
import {GAME_BOARD_TIMER} from "../interface";
import GameBoardTimerCircleGraph from "./game_board_timer_circle_graph";

function GameBoardTimerCircle(props:{ round: number | string; endpoint: string }) {
    const [showState, setShowState] = useState<boolean>(true)
    const [timer, setTimer] = useState<string>()
    const [remain_percent, setRemain_percent] = useState<number>(0)
    const [nowRound, setNowRound] = useState<number>(Number(props.round) + 1)

    function transMinutes(endpoint: string){
        const date = new Date()
        const now_time = date.getHours() + ":" + date.getMinutes()
        const now_sec = date.getSeconds()

        const endpoint_min = Number(endpoint.split(':')[1])
        const now_time_min = Number(now_time.split(':')[1])

        const remain_min = (now_time_min - endpoint_min) < 0 ? 0 : (now_time_min - endpoint_min)
        const remain_count = GAME_BOARD_TIMER - (remain_min * 60 + now_sec)

        // debug obj
        const asd = {
            endpoint: endpoint,
            GAME_BOARD_TIMER: GAME_BOARD_TIMER,
            now_time_min: now_time_min,
            endpoint_min: endpoint_min,
            remain_min: remain_min,
            now_sec: now_sec,
        }

        // console.table(asd)

        return remain_count
    }
    function transMinutesCount(num: number){
        if(num > 300){ num  = 300 }
        let min = Math.floor(num / 60) < 10 ? "0" + Math.floor(num / 60) : Math.floor(num / 60)
        if( min < 5 == false ){
            min = "00";
        }
        const sec = (num % 60) < 10 ? "0" + num % 60 : num % 60
        return min+":"+sec
    }
    function makePercent(remain: number){
        const percent = Number((remain / GAME_BOARD_TIMER).toFixed(4)) * 100
        return percent
    }
    function intervalDef(){
        setShowState(true)
        setRemain_percent(makePercent(transMinutes(props.endpoint)))
        setTimer(transMinutesCount(transMinutes(props.endpoint)))
    }

    useEffect(()=> {
        intervalDef()
        const timerInterval = setInterval(()=>{
            intervalDef()
        }, 1000)

        return () => {
            setShowState(false)
            clearTimeout(timerInterval)
        }
    }, [props.endpoint, showState])
    useEffect(()=> {
        setNowRound(Number(props.round) + 1)
    }, [props.round])
    return (
        showState
            ? <div className={"board-timer-circle"}>
                <div className={"inner-circle"}>

                    <div className={"timer-state"}>
                        <div className={"guide"}>
                            <p>{timer}</p>
                            <p>{nowRound}회 추첨 시작</p>
                        </div>
                    </div>
                    <GameBoardTimerCircleGraph per={Number(remain_percent)} />
                </div>
            </div>
            : <div></div>
    );
}

export default GameBoardTimerCircle;