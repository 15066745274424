import React, {useEffect, useState} from 'react';

function GraphBarCircle(props: { per: number, color: string}) {
    const RADIUS = 40;
    const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
    const CIRCUMFERENCE_FIXED = Number(CIRCUMFERENCE.toFixed(0))
    const progress = props.per / 100;
    const offset = Number((CIRCUMFERENCE * (1 - progress)).toFixed(0));

    const [dashOffset, setDashOffset] = useState(CIRCUMFERENCE_FIXED)
    useEffect(()=> {
        const endpoint = offset
        setTimeout(()=> {
            if( endpoint < dashOffset ){
                setDashOffset(dashOffset - 1)
            }
        }, 2)
    })
    return (
        <div className="circle_progress_wrap">
            <svg className={"circle_progress " + props.color} width="156" height="156" viewBox="0 0 100 100">
                <defs>
                    <linearGradient id={"linear-"+props.color} x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" className={"from"} />
                        <stop offset="100%" className={"to"} />
                    </linearGradient>
                </defs>
                <circle className="bar" cx="50" cy="50" r="40"
                        strokeWidth="18.5"
                        strokeDasharray={CIRCUMFERENCE}
                        strokeDashoffset={dashOffset}
                        stroke={ `${"url(#linear-"+props.color+")"}` }
                />
            </svg>
        </div>
    );
}

export default GraphBarCircle;