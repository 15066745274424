import React, { useEffect, useState } from "react"
import dayjs from "dayjs";
/** conponent */
import GraphBarCircle from '../../components/graph/circle';
import PerCount from "../minigame/per_count";
import GraphSML from "../../components/graph/graph_sml";
/** interface */
import {
    DEFAULT_STATS_TYPE,
    GAME_TYPE_OPTIONS,
    GAME_TYPES,
    GameResult,
    GameResultData, GameResultStats,
    GameType,
    LABELS,
    STANDARS
} from "./interface";
/** css */
import "./happyBallRillGame.scss"

export default function () {
    const [gameResults, setGameResults] = useState<GameResult[]>();
    const fetch_url = "/api/happyball/results/"
    const iframe_url = "/game/"
    // const fetch_url = "https://www.happyball.co.kr/api/happyball/results/"
    // const iframe_url = "https://www.happyball.co.kr/game/"

    useEffect(() => {
        fetch(fetch_url)
            .then(async response => {
                if (response.ok) {
                    const data = await response.json();
                    setGameResults(makeGameResults(data));
                } else {
                    alert('게임 결과 fetch 실패');
                }
            })
            .catch((e)=> {
                console.log(e)
            })
    }, [])

    function fetchResults(): any {
        if (!gameResults) {
            return
        }
        // console.log(gameResults.last())
        // 현재 state에 포함된 가장 마지막 라운드 이후 데이터를 요청
        const fromRound = gameResults[0].round + 1
        fetch(`${fetch_url}?from=${fromRound}`)
            .then(async response => {
                if (response.ok) {
                    const data = await response.json();
                    let fetchedResults = makeGameResults(data);
                    if (fetchedResults.length > 0) {
                        setGameResults(fetchedResults.concat(gameResults.slice(0, -fetchedResults.length)));
                    }
                } else {
                    alert('게임 결과 fetch 실패');
                }
            })
    }

    // 결과 값이 추가될 때마다 다음 결과값 요청을 위한 세팅
    // 다음 라운드 결과가 나올 때까지 기다렸다가 (getLeftTime())
    // 매 5초마다 요청(성공 시(gameResults가 바뀌면) clearInterval)
    useEffect(() => {
        if (!gameResults) {
            return
        }
        let intervalId: NodeJS.Timer
        let timeoutId = setTimeout(() => {
            fetchResults();
            intervalId = setInterval(fetchResults, 5 * 1000)
        }, getLeftTime(gameResults[0]?.game_begin_dt))

        return () => {
            clearTimeout(timeoutId)
            clearInterval(intervalId)
        }
    }, [gameResults])
    if (gameResults) {

        return (
            <div id={"happyball-layout"}>
                {/*모바일*/}
                <iframe src={iframe_url} width="840" height={"750"} frameBorder="0" style={{ maxWidth: "100%" }}></ iframe>

                {/*PC*/}
                {/*<iframe src={iframe_url} width="840" height={"600"} frameBorder="0"></ iframe>*/}
                <div id="powerball-cell-layout">
                    <GameStats gameResults={gameResults} />
                    <GameGraph gameResults={gameResults} />
                    <GameResultTable gameResults={gameResults} />
                </div>
            </div>)
    } else {
        return <div>로딩중</div>
    }
}

function GameStats({ gameResults }: { gameResults: GameResult[] }) {
    const [statsType, setStatsType] = useState<GameType>(DEFAULT_STATS_TYPE)
    const [tabProps, setSelectTab] = useState(6)
    const matrix = makeGameResultsMatrix(gameResults, statsType);
    const stats = makeGameResultsStats(gameResults, statsType);
    return (
        <div id={"public-powerball-cell"}>
            <div className={"powerball-cell-tab"}>
                <TabUnderBar left={tabProps} />
                {GAME_TYPES.map((type, index) =>
                    <button key={index} className={statsType == type ? "select" : ""}
                        onClick={
                            (e) => {
                                setStatsType(type)
                                setSelectTab(e.currentTarget.offsetLeft)
                            }
                        }>{LABELS[type]}</button>
                )}
            </div>
            <div className={`${statsType}`}>
                <div className="additional">
                    <div className="summary">
                        {GAME_TYPE_OPTIONS[statsType].map((option, key_) =>
                            <div key={option}>
                                <div>
                                    <span className={"round "+ option as keyof typeof LABELS}>{LABELS[option as keyof typeof LABELS]}</span>
                                    {stats.countMap.get(option)}번
                                    <span className={"--font-10"}>({(stats.countMap.get(option) / [...stats.countMap.values()].reduce((a, b) => a + b, 0) * 100).toFixed(2)}%)</span>
                                    {" "}{stats.maxLengthMap.get(option)}연속
                                </div>
                                { GAME_TYPE_OPTIONS[statsType].length != (key_ + 1) ? <span className={"bar"}></span> : "" }
                            </div>
                        )}
                    </div>
                    <div>
                        <div><span className={"round Rjrdla"}>꺽임</span>{stats.numOfTurn}번</div>
                        {
                            statsType != 'balls_size'
                                ? <>
                                    <span className={"bar"}></span>
                                    <div><span className={"round vhdekd"}>퐁당</span>{stats.numOfSingle}번({(stats.numOfSingle / stats.numOfTurn * 100).toFixed(2) + '%'}) {stats.maxSingleInRow}연속</div>
                                    <span className={"bar"}></span>
                                    <div><span className={"round dusthr"}>연속</span>{stats.numOfMultiple}번({(stats.numOfMultiple / stats.numOfTurn * 100).toFixed(2) + '%'}) {Math.max(...stats.maxLengthMap.values())}연속</div>
                                  </>
                                : ""
                        }
                    </div>
                </div>
                <div className="powerball-cell-table">
                    <div className="scroll-area">
                        <div className={"scroll-layout"}>
                            {matrix.map((gameResults, index) => {
                                const currentGameType = gameResults[0][statsType];
                                const bar_height = gameResults.length * 30
                                return (
                                    <div key={index} className={"col " + `${currentGameType}`}>
                                        <span className={"head"}>{LABELS[currentGameType]}</span>
                                        <div className={"in-layout"}>
                                            {[...Array(stats.maxLength - gameResults.length + 1)].map((_, index) =>
                                                <span key={index}></span>
                                            )}
                                            <div className={"down-bar"} style={{ height: bar_height }}></div>
                                            {gameResults.map(gameResult =>
                                                <span key={gameResult.round}
                                                    className={"in"}>{gameResult.round_daily}</span>
                                            )}
                                        </div>
                                        <span className={"length"}>{gameResults.length}</span>
                                        <span className={"round"}>{matrix.length - index}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// 기본 게임 결과에 필요한 자료들을 추가한다.
export function makeGameResults(results: GameResultData[]): GameResult[] {
    return results.map(result => {
        const balls_sum = result.balls.reduce((sum, ball) => sum + ball, 0);
        // 2시간 시차 + 4분 30초 타이밍
        const game_datetime = dayjs(result.game_begin_dt).add(2, 'hour').add(4, 'minute').add(30, 'second')
        // 5분에 1회차씩
        let round_daily = game_datetime.hour() * 12 + (game_datetime.minute() / 5)
        round_daily = round_daily === 0 ? 288 : round_daily
        return {
            ...result,
            round_daily,
            game_date: game_datetime.subtract(1, 'second').format('YYYY-MM-DD'), // 마지막 회차가 정각이므로 1초를 빼고 계산
            game_time: round_daily === 288 ? '24:00' : game_datetime.format('HH:mm'),

            happy_ball_odd_even: result.happy_ball % 2 ? 'odd' : 'even',
            happy_ball_under_over: result.happy_ball < STANDARS.HAPPY_BALL_UNDER_OVER ? 'under' : 'over',
            happy_ball_section: result.happy_ball < STANDARS.HAPPY_BALL_A_B ? 'A' :
                result.happy_ball < STANDARS.HAPPY_BALL_B_C ? 'B' :
                    result.happy_ball < STANDARS.HAPPY_BALL_C_D ? 'C' : 'D',

            balls_sum,
            balls_odd_even: balls_sum % 2 ? 'odd' : 'even',
            balls_under_over: balls_sum < STANDARS.BALL_UNDER_OVER ? 'under' : 'over',
            balls_size: balls_sum < STANDARS.BALL_SMALL_MEDIUM ? 'small' : balls_sum < STANDARS.BALL_MEDIUM_LARGE ? 'medium' : 'large',
            balls_section: balls_sum < STANDARS.BALL_A_B ? 'A'
                : balls_sum < STANDARS.BALL_B_C ? 'B'
                    : balls_sum < STANDARS.BALL_C_D ? 'C'
                        : balls_sum < STANDARS.BALL_D_E ? 'D'
                            : balls_sum < STANDARS.BALL_E_F ? 'E'
                                : 'F',
        }
    });
}

// 타입에 맞게 연속되는 게임 결과를 묶어 Matrix로 만든다
function makeGameResultsMatrix(results: GameResult[], statsType: GameType): GameResult[][] {
    let index = 0;
    let lastGameTypeOption;
    const gameResultsMatrix: GameResult[][] = []

    while (index < results.length) {
        const currentResult = results[index];
        const currentGameTypeOption = currentResult[statsType];

        if (currentGameTypeOption === lastGameTypeOption) {
            // 같은 타입이라면 마지막 배열에 추가
            gameResultsMatrix.last().push(currentResult)
        } else {
            // 다른 타입이라면 새로운 배열 추가
            gameResultsMatrix.push([currentResult])
        }
        lastGameTypeOption = currentGameTypeOption
        index += 1
    }
    return gameResultsMatrix
}

// 통계 자료를 뽑는다
function makeGameResultsStats(gameResults: GameResult[], statsType: GameType): GameResultStats {
    // 옵션별 빈도
    let countMap = new Map();
    // 옵션별 max length
    let maxLengthMap = new Map();
    for (const option of GAME_TYPE_OPTIONS[statsType]) {
        countMap.set(option, 0);
        maxLengthMap.set(option, 0);
    }
    // 꺽임
    let numOfTurn = 0;
    // 퐁당
    let countSingle = 0
    // 연속 퐁당 횟수
    let currentSingleInRow = 0;
    // 최대 연속 퐁당 횟수
    let maxSingleInRow = 0;
    // 현재 연속 횟수
    let numOfCurrentTurn = 0

    let lastType;
    for (const gameResult of gameResults) {
        const currentTypeOption = gameResult[statsType];

        countMap.set(currentTypeOption, countMap.get(currentTypeOption) + 1);
        // 처음인 경우
        if (!lastType) {
            numOfCurrentTurn = 1;
            numOfTurn++;
            currentSingleInRow = 1;
        }
        // 연속인 경우
        else if (currentTypeOption === lastType) {
            numOfCurrentTurn++;
            maxSingleInRow = Math.max(maxSingleInRow, currentSingleInRow);
            currentSingleInRow = 0;
        }
        // 꺽이는 경우 
        else {
            if (numOfCurrentTurn === 1) {
                countSingle++;
            }
            numOfCurrentTurn = 1;
            numOfTurn++;
            currentSingleInRow++;
        }

        // 현재 연속횟수가 타입별 최고횟수를 넘는 경우
        if (numOfCurrentTurn > maxLengthMap.get(currentTypeOption)) {
            maxLengthMap.set(currentTypeOption, numOfCurrentTurn);
        }

        lastType = currentTypeOption;
    }

    if (numOfCurrentTurn === 1) {
        countSingle++;
    }


    return {
        numOfTurn,
        numOfSingle: countSingle,
        maxSingleInRow,
        numOfMultiple: numOfTurn - countSingle,
        countMap,
        maxLengthMap,
        maxLength: Math.max(...Array.from(maxLengthMap.values())),
    }
}

// 하단 그래프
export function getLeftTime(last_game_begin_dt: string) {
    const lastGameTime = new Date(last_game_begin_dt);
    // 2시간 한국-라오스 시간차 + 5분(게임별 텀) + 4분35초(베팅시작에서 추첨까지의 텀) + 1.5초(딜레이)
    return lastGameTime.getTime() + (2 * 60 * 60 * 1000 + 5 * 60 * 1000 + 275 * 1000 + 1.5 * 1000) - Date.now();
}
export function getGraphDataObject({ gameResults }: { gameResults: GameResult[] }) {
    const graphData = makeGraphData(gameResults)
    const Obj = {
        powerBall: {
            perCount: {
                odd: graphData.get('happy_ball_odd_even').get('odd'),
                even: graphData.get('happy_ball_odd_even').get('even'),
                under: graphData.get('happy_ball_under_over').get('under'),
                over: graphData.get('happy_ball_under_over').get('over'),
            },
            percent: {
                odd: Number((graphData.get('happy_ball_odd_even').get('odd') / gameResults.length * 100).toFixed(2)),
                even: Number((graphData.get('happy_ball_odd_even').get('even') / gameResults.length * 100).toFixed(2)),
                under: Number((graphData.get('happy_ball_under_over').get('under') / gameResults.length * 100).toFixed(2)),
                over: Number((graphData.get('happy_ball_under_over').get('over') / gameResults.length * 100).toFixed(2)),
            }
        },
        normalBall: {
            perCount: {
                odd: graphData.get('balls_odd_even').get('odd'),
                even: graphData.get('balls_odd_even').get('even'),
                under: graphData.get('balls_under_over').get('under'),
                over: graphData.get('balls_under_over').get('over'),
            },
            percent: {
                odd: Number((graphData.get('balls_odd_even').get('odd') / gameResults.length * 100).toFixed(2)),
                even: Number((graphData.get('balls_odd_even').get('even') / gameResults.length * 100).toFixed(2)),
                under: Number((graphData.get('balls_under_over').get('under') / gameResults.length * 100).toFixed(2)),
                over: Number((graphData.get('balls_under_over').get('over') / gameResults.length * 100).toFixed(2)),
            }
        },
        sml: {
            perCount: {
                s: Number(graphData.get('balls_size').get('small')),
                m: Number(graphData.get('balls_size').get('medium')),
                l: Number(graphData.get('balls_size').get('large'))
            },
            percent: {
                s: Number((graphData.get('balls_size').get('small') / gameResults.length * 100).toFixed(2)),
                m: Number((graphData.get('balls_size').get('medium') / gameResults.length * 100).toFixed(2)),
                l: Number((graphData.get('balls_size').get('large') / gameResults.length * 100).toFixed(2))
            }
        }
    }
    return Obj
}
function GameGraph(gameResults: { gameResults: GameResult[]; }) {
    const data = getGraphDataObject(gameResults)
    const powerBall = data.powerBall
    const normalBall = data.normalBall
    const sml = data.sml

    const [powerballOddEvenWin, setPowerballOddEvenWin] = useState<string>('red')
    const [powerballOverUnderWin, setPowerballOverUnderWin] = useState<string>('red')
    const [normalOddEvenWin, setNormalOddEvenWin] = useState<string>('red')
    const [normalOverUnderWin, setNormalOverUnderWin] = useState<string>('red')
    useEffect(()=> {
        setPowerballOddEvenWin(powerBall.perCount.odd < powerBall.perCount.even ? "red" : "blue")
        setPowerballOverUnderWin(powerBall.perCount.under < powerBall.perCount.over ? "red" : "blue")
        setNormalOddEvenWin(normalBall.perCount.odd < normalBall.perCount.even ? "red" : "blue")
        setNormalOverUnderWin(normalBall.perCount.under < normalBall.perCount.over ? "red" : "blue")
    }, [gameResults])
    return (
        <>
            <div id={"powerball-result-graph"}>
                <div>
                    <header>파워볼</header>
                    <main>
                        <div className={"graph-circle overunder"}>
                            <GraphBarCircle per={Number(powerBall.percent.odd)} color={powerballOddEvenWin} />
                            <div className={"inner blue"}>
                                <div>
                                    <p className={"item-tit " + (powerballOddEvenWin == "blue" ? powerballOddEvenWin : "default") }>홀</p>
                                    <p className={"item-contents"}>
                                        <span>{powerBall.perCount.odd}번</span>
                                        <PerCount count={powerBall.percent.odd} />
                                    </p>
                                </div>
                                <div>
                                    <p className={"item-tit " + (powerballOddEvenWin == "red" ? powerballOddEvenWin : "default") }>짝</p>
                                    <p className={"item-contents"}>
                                        <span>{powerBall.perCount.even}번</span>
                                        <PerCount count={powerBall.percent.even} />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={"graph-circle overunder"}>
                            <GraphBarCircle per={Number(powerBall.percent.under)} color={powerballOverUnderWin} />
                            <div className={"inner red"}>
                                <div>
                                    <p className={"item-tit " + (powerballOverUnderWin == "blue" ? powerballOverUnderWin : "default")}>언더</p>
                                    <p className={"item-contents"}>
                                        <span>{powerBall.perCount.under}번</span>
                                        <PerCount count={powerBall.percent.under} />
                                    </p>
                                </div>
                                <div>
                                    <p className={"item-tit " + (powerballOverUnderWin == "red" ? powerballOverUnderWin : "default")}>오버</p>
                                    <p className={"item-contents"}>
                                        <span>{powerBall.perCount.over}번</span>
                                        <PerCount count={powerBall.percent.over} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div>
                    <header>일반볼</header>
                    <main>
                        <div className={"graph-circle overunder"}>
                            <GraphBarCircle per={Number(normalBall.percent.odd)} color={normalOddEvenWin} />
                            <div className={"inner blue"}>
                                <div>
                                    <p className={"item-tit " + (normalOddEvenWin == "blue" ? normalOddEvenWin : "default")}>홀</p>
                                    <p className={"item-contents"}>
                                        <span>{normalBall.perCount.odd}번</span>
                                        <PerCount count={normalBall.percent.odd} />
                                    </p>
                                </div>
                                <div>
                                    <p className={"item-tit " + (normalOddEvenWin == "red" ? normalOddEvenWin : "default")}>짝</p>
                                    <p className={"item-contents"}>
                                        <span>{normalBall.perCount.even}번</span>
                                        <PerCount count={normalBall.percent.even} />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={"graph-circle overunder"}>
                            <GraphBarCircle per={Number(normalBall.percent.under)} color={normalOverUnderWin} />
                            <div className={"inner red"}>
                                <div>
                                    <p className={"item-tit " + (normalOverUnderWin == "blue" ? normalOverUnderWin : "default")}>언더</p>
                                    <p className={"item-contents"}>
                                        <span>{normalBall.perCount.under}번</span>
                                        <PerCount count={normalBall.percent.under} />
                                    </p>
                                </div>
                                <div>
                                    <p className={"item-tit " + (normalOverUnderWin == "red" ? normalOverUnderWin : "default")}>오버</p>
                                    <p className={"item-contents"}>
                                        <span>{normalBall.perCount.over}번</span>
                                        <PerCount count={normalBall.percent.over} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div>
                    <main>
                        <div className={"graph-circle l-m-s"}>
                            <div className={"inner"}></div>
                            <GraphSML s={sml.percent.s} m={sml.percent.m} l={sml.percent.l} />
                        </div>

                        <div className={"graph-count"}>
                            <div>
                                <span className={"--color-small"}>●</span>
                                <p>소 - {sml.perCount.s} <PerCount count={sml.percent.s} /> </p>
                            </div>
                            <div>
                                <span className={"--color-medium"}>●</span>
                                <p>중 - {sml.perCount.m} <PerCount count={sml.percent.m} /> </p>
                            </div>
                            <div>
                                <span className={"--color-large"}>●</span>
                                <p>대 - {sml.perCount.l} <PerCount count={sml.percent.l} /> </p>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

function makeGraphData(gameResults: GameResult[]) {
    const map = new Map()
    for (const gameType of GAME_TYPES) {
        const optionMap = new Map()
        const options = GAME_TYPE_OPTIONS[gameType]
        for (const option of options) {
            optionMap.set(option, 0)
        }
        map.set(gameType, optionMap)
    }

    for (const gameResult of gameResults) {
        for (const type of Array.from(map.keys()) as GameType[]) {
            const option = gameResult[type]
            map.get(type).set(option, map.get(type).get(option) + 1)
        }
    }
    return map
}

function GameResultTable({ gameResults }: { gameResults: GameResult[] }) {
    return (
        <div className={"powerball-result-table"}>
            <table>
                <thead>
                <tr>
                    <th rowSpan={2}>회차</th>
                    <th rowSpan={2}>시간</th>

                    <th colSpan={4}>파워볼</th>
                    <th colSpan={6}>일반볼</th>
                </tr>
                <tr>
                    <th>결과</th>
                    <th>구간</th>
                    <th>홀짝</th>
                    <th>언오버</th>

                    <th>결과</th>
                    <th>합</th>
                    <th>구간</th>
                    <th>홀짝</th>
                    <th>언오버</th>
                    <th>대중소</th>
                </tr>
                </thead>
                <tbody>
                {gameResults.map(gameResult =>
                    <tr key={gameResult.round}>
                        <td>
                            {`${gameResult.game_date}`} &nbsp;&nbsp; <strong>{gameResult.round_daily}</strong>
                            <br />
                            ({gameResult.round})
                        </td>
                        <td>{gameResult.game_time}</td>

                        <td className={"result"}>{/* 결과 */}{gameResult.happy_ball}</td>
                        <td>{/* 구간 */}
                            <span className={`${"round section"}`}>{LABELS[gameResult.happy_ball_section]}</span>
                        </td>
                        <td>{/* 홀짝 */}
                            <span className={`${"round " + gameResult.happy_ball_odd_even}`}>{LABELS[gameResult.happy_ball_odd_even]}</span>
                        </td>
                        <td>{/* 언오버 */}
                            <span className={`${"round " + gameResult.happy_ball_under_over}`}>{LABELS[gameResult.happy_ball_under_over]}</span>
                        </td>
                        <td className={"result"}>{/* 결과 */}{gameResult.balls.join(', ')}</td>
                        <td className={"result"}>{/* 합 */}{gameResult.balls_sum}</td>
                        <td>{/* 구간 */}
                            <span className={`${"round section"}`}>{LABELS[gameResult.balls_section]}</span>
                        </td>
                        <td>{/* 홀짝 */}
                            <span className={`${"round " + gameResult.balls_odd_even}`}>{LABELS[gameResult.balls_odd_even]}</span>
                        </td>
                        <td>{/* 언오버 */}
                            <span className={`${"round " + gameResult.balls_under_over}`}>{LABELS[gameResult.balls_under_over]}</span>
                        </td>
                        <td>{/* 대중소 */}
                            <span className={`${"round " + gameResult.balls_size}`}>{LABELS[gameResult.balls_size]}</span>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

// 탭 언더 라인 이동
function TabUnderBar(props: { left: number }) {
    const [left, setLeft] = useState(6)
    useEffect(() => {
        setLeft(props.left)
    })
    return (
        <span className="over-select-tab" style={{ left: left }}></span>
    )
}