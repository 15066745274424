import React, {useContext, useEffect, useRef, useState} from 'react';
import {MuteContext} from "./muteContext";


function AudioPlayer(props:{src: string; play: boolean; autoplay: boolean; loop: boolean}) {
    const { mute } = useContext(MuteContext)
    const [reRender, setReRender] = useState<boolean>(true)
    const audioPlay = useRef<HTMLAudioElement>(null)
    function audioPlayStart(){
        if(audioPlay.current && props.play){
            audioPlay.current.volume = 0.3;
            const audio_ = audioPlay.current.play()
            audio_.then(()=> {

            }).catch((e)=> {
                console.log(e)
                setTimeout(()=> {
                    setReRender(!reRender)
                }, 1000)
            })
        }
    }
    useEffect(()=>{
        if( props.play ){
            audioPlayStart()
        }
    }, [props.play])
    return (
        <audio src={props.src}
               ref={audioPlay}
               autoPlay={props.autoplay}
               loop={props.loop}
               muted={mute}
               typeof="audio/mp3"/>
    );
}
AudioPlayer.defaultProps = {
    src: "",
    play: false,
    autoplay: false,
    loop: false,
}

export default AudioPlayer;