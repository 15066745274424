import React, {useEffect, useState} from 'react';


function setDefaultDate(){
    const date = new Date()
    const hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
    const min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
    const sec = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
    return date.getFullYear() +"."+ date.getMonth() + 1 +"."+ date.getDate() +" "+ hour +":"+ min +":"+ sec
}
function GameBoardGlobalTimer(props:{}) {
    const [timer, setTimer] = useState<string>(setDefaultDate)
    useEffect(()=> {
        setInterval(()=> {
            setTimer(setDefaultDate)
        },1000)
    }, [])
    return (
        <div className="board-timer global">{timer}</div>
    );
}

export default GameBoardGlobalTimer;