import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// Error
import ErrorPage from "src/apps/error/error-page";
import NotFoundPage from 'src/apps/error/not-fond-page';
// Root
import RootLayout from "src/layouts/root";
import HomePage from "src/apps/happyball/index";
// Room
import ChatRoom from 'src/apps/room/chat-room';
// Routes
import communityRoute from 'src/apps/community/_route';
import sportsRoute from 'src/apps/sports/_route';
import serviceCenterRoute from 'src/apps/service_center/_route';
import happyballRoute from 'src/apps/happyball/route';
import minigameRoute from 'src/apps/minigame/route';
import rankingRoute from 'src/apps/ranking/route';
import roomRoute from 'src/apps/room/route';
import marketRoute from 'src/apps/market/route';
import userRoute from 'src/apps/user/_route';
// Css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/asset/css/css.css';
import HappyBallGameBoardIframe from "./apps/happyball/game/happy_ball_game_board_iframe";


const route = createBrowserRouter([
  {
    path: "/",
    // element: <RootLayout />,
    children: [
      {
        // errorElement: <ErrorPage />,
        children: [
          { path: "", element: <HomePage /> },
          { path: "/game/", element: <HappyBallGameBoardIframe /> },
          // serviceCenterRoute,
          // userRoute,
          // communityRoute,
          // sportsRoute,
          // happyballRoute,
          // minigameRoute,
          // rankingRoute,
          // roomRoute,
          // marketRoute,
          // { path: "*", element: <NotFoundPage /> }
        ]
      },
    ]
  },
  // { path: "/chat-room/:roomId", element: <ChatRoom /> },
  // { path: "/game/", element: <HappyBallGameBoardIframe /> },
  // { path: "/test", element: <Test /> }
])

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={route} />
);
